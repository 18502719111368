define([
    'santa-components',
    'lodash',
    'skins',
    'componentsCore',
    'coreUtils',
    'galleriesCommon',
    'displayer',
    'reactDOM',
    'zepto',
    'matrixGallery/skins/skins.json'
], function (
    santaComponents,
    _,
    skinsPackage,
    componentsCore,
    coreUtils,
    galleriesCommon,
    displayer,
    reactDOM,
    $,
    skinsJson
) {
    'use strict';

    const matrixCalculations = coreUtils.matrixCalculations;
    const matrixScalingCalculations = coreUtils.matrixScalingCalculations;
    const galleriesHelperFunctions = galleriesCommon.utils.galleriesHelperFunctions;
    const galleriesCommonLayout = coreUtils.galleriesCommonLayout;

    const displayerSkinParams = ['topPadding', 'imgHeightDiff'];

    function hideShowMoreButton(rowsNumber, numCols, itemsLen) {
        return itemsLen <= rowsNumber * numCols ? 'fullView' : 'hiddenChildren';
    }

    function enforceMinimalHeightOnGallery(height) {
        return Math.max(galleriesHelperFunctions.MIN_GALLERY_HEIGHT, height);
    }

    /**
     * currentStyle / newStyle can be nonexistent (i.e. matrixGallery inside mediaRichText has no style)
     * @param [currentStyle]
     * @param [newStyle]
     * @returns {boolean}
     */
    function shouldRecalculateHeightOnSkinParamChange(currentStyle, newStyle) {
        if (currentStyle && newStyle && currentStyle.skin === newStyle.skin) {
            const currentStyleRelevantProps = _.pick(currentStyle.style.properties, displayerSkinParams);
            const newStyleRelevantProps = _.pick(newStyle.style.properties, displayerSkinParams);
            return !_.isEqual(currentStyleRelevantProps, newStyleRelevantProps);
        }
        return false;
    }


    /**
     * @class components.MatrixGallery
     * @extends {core.skinBasedComp}
     * @extends {componentsCore.skinInfo}
     */
    const matrixGallery = {
        displayName: 'MatrixGallery',

        propTypes: _.assign({
            shouldResetGalleryToOriginalState: santaComponents.santaTypesDefinitions.RenderFlags.shouldResetGalleryToOriginalState.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            style: santaComponents.santaTypesDefinitions.Component.style.isRequired,
            skin: santaComponents.santaTypesDefinitions.Component.skin.isRequired,
            compTheme: santaComponents.santaTypesDefinitions.Component.theme,
            currentUrlPageId: santaComponents.santaTypesDefinitions.Component.currentUrlPageId.isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            compActions: santaComponents.santaTypesDefinitions.Component.compActions.isRequired
        }, santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(displayer)),

        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.skinInfo, componentsCore.mixins.createChildComponentMixin],

        getInitialState() {
            return this.getInitStateAndResetInstance(this.props);
        },

        getInitStateAndResetInstance(props) {
            const propsToUse = props || this.props;
            this.shouldResetGalleryToOriginalState = propsToUse.shouldResetGalleryToOriginalState;
            const numberOfRows = matrixCalculations.getAvailableRowsNumber(propsToUse.compProp.maxRows, propsToUse.compProp.numCols, propsToUse.compData.items.length);
            const displayDevice = propsToUse.isMobileView ? 'mobileView' : 'desktopView';

            this.showMoreClicked = false;

            this.galleryHeight = enforceMinimalHeightOnGallery(propsToUse.style.height);
            this.itemHeight = matrixCalculations.getItemHeight(propsToUse.compProp.margin, this.galleryHeight, numberOfRows, galleriesHelperFunctions.getSkinHeightDiff(propsToUse.skin));

            return {
                numberOfRows,
                $mobile: propsToUse.isMobileDevice || propsToUse.isTabletDevice() ? 'mobile' : 'notMobile',
                $displayDevice: displayDevice,
                $state: hideShowMoreButton(numberOfRows, propsToUse.compProp.numCols, propsToUse.compData.items.length)
            };
        },

        createDisplayer(displayerData, index, width, height) {
            const displayerSkinName = this.getDisplayerSkin();
            const skin = skinsPackage.skinsMap.get(this.props.skin, this.props.isExperimentOpen);
	        const displayerSkin = skinsPackage.skinsMap.get(displayerSkinName, this.props.isExperimentOpen);
            const bottomGap = skin.exports && skin.exports.bottomGap || 0; // eslint-disable-line no-mixed-operators
            const displayerSkinParamsVals = this.getParams(displayerSkinParams, displayerSkinName);
            const heightDiff = galleriesHelperFunctions.getDisplayerHeightDiff(displayerSkin, displayerSkinParamsVals, this.state.$displayDevice);
            const widthDiff = galleriesHelperFunctions.getDisplayerWidthDiff(displayerSkin, this.state.$displayDevice);
            const sizeAfterScaling = matrixScalingCalculations.getSizeAfterScaling({
                itemHeight: height,
                itemWidth: width,
                displayerData,
                imageMode: this.props.compProp.imageMode,
                widthDiff,
                heightDiff,
                bottomGap
            });
            const displayerPosition = matrixCalculations.getItemPosition(index, width, height, this.props.compProp.margin, this.props.compProp.numCols);
            return this.createChildComponent(displayerData,
                'wysiwyg.viewer.components.Displayer',
                'imageItem',
                {
                    key: displayerData.id,
                    ref: displayerData.id,
                    id: this.props.id + displayerData.id,
                    currentUrlPageId: this.props.currentUrlPageId,
                    galleryId: this.props.id,
                    galleryDataId: this.props.compData.id,
                    imageWrapperSize: sizeAfterScaling.imageWrapperSize,
                    imageIndex: index,
                    heightDiff,
                    widthDiff,
                    bottomGap,
                    style: {
                        width: sizeAfterScaling.displayerSize.width,
                        height: sizeAfterScaling.displayerSize.height,
                        position: 'absolute',
                        left: displayerPosition.left,
                        top: displayerPosition.top
                    }
                }
            );
        },
        createDisplayers() {
            const visibleImages = _.take(this.props.compData.items, this.props.compProp.numCols * this.state.numberOfRows);
            const itemWidth = matrixCalculations.getItemWidth(this.props.compProp.margin, this.props.compProp.numCols, this.props.style.width, galleriesHelperFunctions.getSkinWidthDiff(this.props.skin));
            const itemHeight = this.itemHeight || matrixCalculations.getItemHeight(this.props.compProp.margin, this.galleryHeight, this.state.numberOfRows, galleriesHelperFunctions.getSkinHeightDiff(this.props.skin));
            return _.map(visibleImages, function (item, index) {
                return this.createDisplayer(item, index, itemWidth, itemHeight);
            }.bind(this));
        },

        componentWillReceiveProps(nextProps) { // eslint-disable-line complexity
            const numberOfRows = matrixCalculations.getAvailableRowsNumber(nextProps.compProp.maxRows, nextProps.compProp.numCols, nextProps.compData.items.length);
            const newState = {};

            let shouldRecalculateItemHeight = false;
            /*cols or rows number changed*/
            if (this.props.compProp.maxRows !== nextProps.compProp.maxRows || this.props.compProp.numCols !== nextProps.compProp.numCols || this.props.compData.items.length !== nextProps.compData.items.length) {
                shouldRecalculateItemHeight = true;
                newState.numberOfRows = numberOfRows;
                this.galleryHeight = enforceMinimalHeightOnGallery(galleriesHelperFunctions.getGalleryHeight(this.state.numberOfRows, numberOfRows, this.props.compProp.margin, this.props.skin, this.galleryHeight));
                newState.$state = hideShowMoreButton(numberOfRows, nextProps.compProp.numCols, nextProps.compData.items.length);
            }

            /*gallery Height changed*/
            if (this.props.style.height !== nextProps.style.height) {
                shouldRecalculateItemHeight = true;
                this.galleryHeight = enforceMinimalHeightOnGallery(nextProps.style.height);
            }

            if (this.shouldResetGalleryToOriginalState !== nextProps.shouldResetGalleryToOriginalState) {
                if (this.shouldResetGalleryToOriginalState && this.resetGalleryState) {
                    this.resetGalleryState(nextProps);
                }
                this.shouldResetGalleryToOriginalState = nextProps.shouldResetGalleryToOriginalState;
            }

            /*style props changed*/
            if (shouldRecalculateItemHeight || shouldRecalculateHeightOnSkinParamChange(this.props.compTheme, nextProps.compTheme)) {
                this.itemHeight = matrixCalculations.getItemHeight(nextProps.compProp.margin, this.galleryHeight, numberOfRows, galleriesHelperFunctions.getSkinHeightDiff(this.props.skin));

                const galleryHeightDiff = galleriesHelperFunctions.getSkinHeightDiff(this.props.skin);
                const numOfRows = newState.numberOfRows || this.state.numberOfRows;
                this.galleryHeight = numOfRows * this.itemHeight + (numOfRows - 1) * this.props.compProp.margin + galleryHeightDiff; // eslint-disable-line no-mixed-operators
            }

            if (!_.isEmpty(newState)) {
                this.setState(newState);
            }
        },
        getSkinProperties() {
            const skinProps = {
                'showMore': {
                    children: this.props.compProp.showMoreLabel,
                    onClick: this.showMoreRows,
                    tabIndex: '0',
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },
                'itemsContainer': {
                    children: this.createDisplayers(),
                    style: {
                        height: '100%'
                    },
                    onKeyDown: this.onKeyDown
                },
                '': {
                    'data-height-diff': galleriesHelperFunctions.getSkinHeightDiff(this.props.skin),
                    'data-width-diff': galleriesHelperFunctions.getSkinWidthDiff(this.props.skin),
                    'data-presented-row': this.state.numberOfRows,
                    'data-num-cols': this.props.compProp.numCols,
                    'data-image-mode': this.props.compProp.imageMode,
                    'data-margin': this.props.compProp.margin,
                    style: {
                        height: this.galleryHeight
                    }

                }
            };

            if (this.showMoreClicked || this.state.$state === 'fullView') {
                galleriesCommonLayout.updateSkinPropsForFlexibleHeightGallery(skinProps, this.galleryHeight);
            }

            return skinProps;
        },
        showMoreRows() {
            const newRowsNumber = matrixCalculations.getAvailableRowsNumber(this.state.numberOfRows + this.props.compProp.incRows, this.props.compProp.numCols, this.props.compData.items.length);
            this.showMoreClicked = true;
            this.galleryHeight = enforceMinimalHeightOnGallery(galleriesHelperFunctions.getGalleryHeight(this.state.numberOfRows, newRowsNumber, this.props.compProp.margin, this.props.skin, this.galleryHeight));
            this.registerReLayout();
            this.setState({
                numberOfRows: newRowsNumber,
                $state: hideShowMoreButton(newRowsNumber, this.props.compProp.numCols, this.props.compData.items.length)
            });
        },
        getDisplayerSkin() {
            return this.getSkinExports().imageItem.skin;
        },
        navigateGalleryFocus(currentlyFocusedElement, key) { // eslint-disable-line complexity
            const tabbableElements = componentsCore.utils.accessibility.getTabbaleElements(reactDOM.findDOMNode(this));
            const currentlyFocusedIndex = tabbableElements.indexOf(currentlyFocusedElement);
            const maxItemsPerRow = Math.ceil(tabbableElements.length / this.state.numberOfRows);

            let nextElementToFocusIndex;

            if (key === 'ArrowDown') {
                nextElementToFocusIndex = currentlyFocusedIndex + maxItemsPerRow;

                if (nextElementToFocusIndex >= tabbableElements.length) {
                    nextElementToFocusIndex %= tabbableElements.length;
                }
            }

            if (key === 'ArrowUp') {
                nextElementToFocusIndex = currentlyFocusedIndex - maxItemsPerRow;
                if (nextElementToFocusIndex < 0) {
                    nextElementToFocusIndex = tabbableElements.length - Math.abs(nextElementToFocusIndex);
                }
            }

            if (key === 'ArrowRight') {
                nextElementToFocusIndex = currentlyFocusedIndex + 1;
                if (nextElementToFocusIndex >= tabbableElements.length) {
                    nextElementToFocusIndex = 0;
                }
            }

            if (key === 'ArrowLeft') {
                nextElementToFocusIndex = currentlyFocusedIndex - 1;
                if (nextElementToFocusIndex < 0) {
                    nextElementToFocusIndex = tabbableElements.length - 1;
                }
            }

            const elementToFocus = tabbableElements[nextElementToFocusIndex];
            if (elementToFocus) {
                $(elementToFocus).focus();
            }
        },

        onKeyDown(event) {
            const navigationKeys = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'];
            if (_.includes(navigationKeys, event.key)) {
                event.stopPropagation();
                event.preventDefault();
                this.navigateGalleryFocus(event.target, event.key);
            }
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.MatrixGallery', matrixGallery);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return matrixGallery;
});
